<template>
    <v-dialog max-width="800px" v-model="show">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>New Address</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-form ref="mainForm">
                <v-row no-gutters>
                    <v-col cols="12" class="pa-3">
                            <v-text-field 
                                label="Alias" 
                                outlined
                                dense
                                v-model="form.alias"
                                v-if="show" autofocus  
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field 
                                label="Attention To" 
                                outlined
                                dense
                                v-model="form.attention_to"  
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-checkbox
                                v-model="form.default"
                                label="Default"
                                value=1
                            ></v-checkbox>
                            <v-text-field 
                                label="Address Line-1" 
                                outlined
                                dense
                                v-model="form.address1"  
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field 
                                label="Address Line-2" 
                                outlined
                                dense
                                v-model="form.address2"  
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field 
                                label="Address Line-3" 
                                outlined
                                dense
                                v-model="form.address3"  
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field 
                                label="City" 
                                outlined
                                dense
                                v-model="form.city"  
                                :rules="[rules.required]"
                            ></v-text-field>                                                        
                            <v-text-field 
                                label="Postal Code" 
                                outlined
                                dense
                                v-model="form.postal_code"  
                                :rules="[rules.required]"
                            ></v-text-field>                            
                            <v-select
                                label="State"
                                outlined
                                dense
                                :items="dataList['stateList']"
                                item-text="cs_name"
                                item-value="cs_code"
                                v-model="form.state"
                                :rules="[rules.required]"
                            ></v-select>


                            
                   </v-col>
                </v-row>
                </v-form>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
import ApCustomerService from '@/services/ApCustomerService'
import moment from 'moment'
export default {
    name: "DlgCustAddress",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
      
    },      
    data() {
    return {
        
      message: "",
      form:{
            
            login_id:"",
            customer_id:0,
            customer_address_id:0,
            alias:"",
            attention_to:"",
            address1:"",
            address2:"",
            address3:"",
            city:"",
            postal_code:"",
            state:"",
            country:"",
            default:0,
            
          },
        dataList:[],
        dpicker:{menu1: false,menu2: false,},
        respCode:0,
        rules: {
            required: value => !!value || 'Required.',
            email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
            integer: value => /^[0-9]+$/.test(value) || 'Number only',
            decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
            pinNumber: value => value.length == 6 || 'require 6 digits number',
            searchWord: value => value.length >= 3 || 'require 3 or more letters',
        },
    };
  },
  async created(){
      this.getParameter();

  },
  mounted(){
        this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
        this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

  },
  methods: {
    dlgShow(customer_id,customer_address_id){
        this.form.customer_id = customer_id;
        if(customer_address_id>0)
            this.viewDetails(customer_address_id);
        //console.log('customer_id',customer_id)

    },
    dlgClose(){
        //console.log(this.respCode);
        
        if(this.respCode == 200){

            this.show = false;
        }
        this.show = false;
            
    },
    dlgDelete(customer_id,customer_address_id){
        this.deleteAddress(customer_address_id);
    },    
    async save(){
        let url = "";
        if(this.form.default){
            this.form.default = 1
        }else{
            this.form.default = 0
        }


        if(this.$refs.mainForm.validate()) {
            try{
                if(this.form.customer_address_id > 0)
                    url = 'update_customer_address'
                else
                    url = 'insert_customer_address'
                
                ApCustomerService[url](this.form).then((res) => {
                    this.respCode = res.status;
                    this.$refs.dlgMsg.showDlgBox(true,'success','Customer Address','Success.');

                }).catch((e)=>{
                        //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address',e.response.data.error_message);
                })
                    ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address','Unknown Error');
            } 
        }
    },

    async viewDetails(customer_address_id){
        try{
                let req = {login_id:"",customer_address_id:customer_address_id}
                ApCustomerService.view_customer_address(req).then((res) => {
                    this.form = res.data.data;
                    for (const [key, value] of Object.entries(this.form)) {
                        if(value == null){
                            this.form[key] = "";
                        }
                    }                                
                }).catch((e)=>{
                    //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address',e.response.data.error_message);
                })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address','Unknown Error');
        } 
    },
    async deleteAddress(customer_address_id){
        try{
                let req = {login_id:"",customer_address_id:customer_address_id}
                ApCustomerService.delete_customer_address(req).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Customer Address','Success.');
                }).catch((e)=>{
                    //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address',e.response.data.error_message);
                })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address','Unknown Error');
        } 
    },

    async getParameter(){
        try{
                ApCustomerService.address_param(this.form).then((res) => {
                    //console.log(res);
                    this.dataList = res.data;
                    
                }).catch((e)=>{
                    //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address',e.response.data.error_message);
                })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','Customer Address','Unknown Error');
        } 
    }



  }
};

</script>
